import { useCallback, useMemo } from 'react'
import { SPLUNK_RUM_FIELDS } from '../store/Constants'
import { Logger } from '../utils/Logger'

function useSplunkRum(customEvent) {
  const { moduleName, workflow } = customEvent

  const publishRumEvent = useCallback(
    (attributes) => {
      try {
        window.Shell.v1.monitoring
          .startSpan(moduleName, {
            [SPLUNK_RUM_FIELDS.WORKFLOW]: workflow,
            ...attributes
          })
          .end()
      } catch (error) {
        Logger.error(
          'Not able to start span through Shell.v1.monitoring',
          error
        )
      }
    },
    [moduleName, workflow]
  )

  return useMemo(() => ({ publishRumEvent }), [publishRumEvent])
}

export default useSplunkRum
