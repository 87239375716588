import React from 'react'
import HardwareStatusCheckPage from './pages/HardwareStatusCheck/HardwareStatusCheckPage'
import LaunchDarkly from './store/LaunchDarkly'
import { ConfigProvider } from './store'

const HardwareStatusCheck = ({ ldClientId, ...props }) => {
  return (
    <LaunchDarkly clientId={ldClientId}>
      <ConfigProvider {...props}>
        <HardwareStatusCheckPage />
      </ConfigProvider>
    </LaunchDarkly>
  )
}

export default HardwareStatusCheck
